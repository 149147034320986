#login-body {
  padding-top: 10%;
  padding-bottom: 5rem;
  background: rgba(0, 0, 0, 0.76);
}

.login-form {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 10%;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4%;
  outline: 0;
  max-width: 500px;
}

.tx-tfm {
  text-transform: uppercase;
}

.login-btn {
  border-radius: 50px;
}
